<template>
  <div class="popup_wrap" style="width:1600px;">
    <button class="layer_close" @click.prevent="goBack">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL020P070.001') }}</h1>
      <div class="content_box">
        <div id="realgrid" style="width: 100%; height: 600px" />
      </div>
      <div class="desc_list">
        <ul>
          <li>{{ $t('cp.CTRL020P070.002') }}</li>
        </ul>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg mr5" href="#" @click.prevent="asyncAlert($t('cp.CTRL020P070.004'))">{{ $t('cp.CTRL020P070.003') }}</a>
        <a class="button blue lg" href="#" @click.prevent="goBack">{{ $t('cp.COMMON.007') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import { GridView, LocalDataProvider } from 'realgrid'
  let gridView = GridView
  let provider = LocalDataProvider

  const fields = [
    { fieldName: 'bkgShipper', dataType: 'text' },
    { fieldName: 'scenarioCd', dataType: 'text' },
    { fieldName: 'scenarioNm', dataType: 'text' },
    { fieldName: 'effectFr', dataType: 'text' },
    { fieldName: 'effectTo', dataType: 'text' },
    { fieldName: 'allShipperYn', dataType: 'text' },
    { fieldName: 'actionCd', dataType: 'text' },
    { fieldName: 'actionNm', dataType: 'text' },
    { fieldName: 'detailNm', dataType: 'text' },
    { fieldName: 'applyFr', dataType: 'text' },
    { fieldName: 'applyTo', dataType: 'text' },
    { fieldName: 'porCtr', dataType: 'text' },
    { fieldName: 'porPlc', dataType: 'text' },
    { fieldName: 'ts1', dataType: 'text' },
    { fieldName: 'ts2', dataType: 'text' },
    { fieldName: 'ts3', dataType: 'text' },
    { fieldName: 'dlyCtr', dataType: 'text' },
    { fieldName: 'dlyPlc', dataType: 'text' }
  ]

  const columns = [
    { name: 'bkgShipper', fieldName: 'bkgShipper', header: { text: app.$t('cp.CTRL020P070.005') }, width: 25, editable: false },
    { name: 'scenarioCd', fieldName: 'scenarioCd', header: { text: app.$t('cp.CTRL020P070.006') }, width: 35, editable: false },
    { name: 'scenarioNm', fieldName: 'scenarioNm', header: { text: app.$t('cp.CTRL020P070.007') }, width: 55, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'effectFr', fieldName: 'effectFr', header: { text: app.$t('cp.CTRL020P070.008') }, width: 35, editable: false },
    { name: 'effectTo', fieldName: 'effectTo', header: { text: app.$t('cp.CTRL020P070.009') }, width: 35, editable: false },
    { name: 'allShipperYn', fieldName: 'allShipperYn', header: { text: app.$t('cp.CTRL020P070.010') }, width: 20, editable: false },
    { name: 'actionCd', fieldName: 'actionCd', header: { text: app.$t('cp.CTRL020P070.011') }, width: 25, editable: false },
    { name: 'actionNm', fieldName: 'actionNm', header: { text: app.$t('cp.CTRL020P070.012') }, width: 40, editable: false, styleName: 'text_left', renderer: { showTooltip: true } },
    { name: 'detailNm', fieldName: 'detailNm', header: { text: app.$t('cp.CTRL020P070.013') }, width: 25, editable: false },
    { name: 'applyFr', fieldName: 'applyFr', header: { text: app.$t('cp.CTRL020P070.014') }, width: 25, editable: false },
    { name: 'applyTo', fieldName: 'applyTo', header: { text: app.$t('cp.CTRL020P070.015') }, width: 25, editable: false },
    { name: 'porCtr', fieldName: 'porCtr', header: { text: app.$t('cp.CTRL020P070.016') }, width: 23, editable: false },
    { name: 'porPlc', fieldName: 'porPlc', header: { text: app.$t('cp.CTRL020P070.017') }, width: 23, editable: false },
    { name: 'ts1', fieldName: 'ts1', header: { text: app.$t('cp.CTRL020P070.018') }, width: 15, editable: false },
    { name: 'ts2', fieldName: 'ts2', header: { text: app.$t('cp.CTRL020P070.019') }, width: 15, editable: false },
    { name: 'ts3', fieldName: 'ts3', header: { text: app.$t('cp.CTRL020P070.020') }, width: 15, editable: false },
    { name: 'dlyCtr', fieldName: 'dlyCtr', header: { text: app.$t('cp.CTRL020P070.021') }, width: 23, editable: false },
    { name: 'dlyPlc', fieldName: 'dlyPlc', header: { text: app.$t('cp.CTRL020P070.022') }, width: 23, editable: false }
  ]

  export default {
    name: 'ConfirmTargetPopup',
    props: {
      parentInfo: {
        type: Object,
        default: function () {
          return {
            position: ''
          }
        }
      }
    },
    data: function () {
      return {
        confirmYn: ''
      }
    },
    computed: {
    },
    watch: {
    },
    created () {
    },
    mounted: function () {
      const $vm = this
      provider = new LocalDataProvider(true)
      gridView = new GridView('realgrid')
      gridView.setDataSource(provider)
      // 그리고 헤더 높이 조정
      gridView.header.heights = [45]
      gridView.displayOptions.rowHeight = 34
      gridView.displayOptions.fitStyle = 'even'
      gridView.footers.visible = false
      gridView.setStateBar({
        visible: false
      })
      gridView.setRowIndicator({
        visible: false
      })
      gridView.setCheckBar({
        visible: false
      })
      gridView.footers.visible = false
      // 그리드 필드 생성
      provider.setFields(fields)
      gridView.setColumns(columns)
      gridView.displayOptions.selectionStyle = 'rows'

      gridView.setSortingOptions({
        keepFocusedRow: true,
        style: 'inclusive',
        showSortOrder: true
      })

      $vm.setInfo()
    },
    methods: {
      setInfo: function () {
        provider.setRows(this.parentInfo.confirmShipperInfo)
      },
      confirm: function () {
        this.confirmYn = 'Y'
        this.$emit('close', this.confirmYn)
        this.layerClose()
      },
      goBack: function () {
        this.confirmYn = 'N'
        this.$emit('close', this.confirmYn)
        this.layerClose()
      },
      layerClose: function () {
        $('.popup_dim').fadeOut()
        $('body,html').removeAttr('style')
        return false
      },
      async asyncAlert (msg) {
        let obj = {
          alertType: 'normal',
          showXclose: true,
          customCloseBtnClass: 'button blue lg',
          customCloseBtnText: this.$t('cp.COMMON.007'),
          customConfirmBtnText: this.$t('cp.COMMON.006'),
          customConfirmBtnClass: 'button blue lg',
          useConfirmBtn: true,
          title: this.$t('cp.CTRL020P070.023'),
          message: msg,
          type: 'info'
        }
        if (await this.$ekmtcCommon.asyncAlert(obj)) {
          this.confirm()
        }
      }
    }
  }
</script>
<style>
  .desc_list ul li {
    font-size: 14px;
  }
</style>
